import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../store/action/index";
import { useHistory } from "react-router-dom";

const LayoutLogin = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actionCreator.populateUserData());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.email_verified_at) {
        //history.push("/dashboard");
      }
    }
  }, [userData]);

  return <>{children}</>;
};

export default LayoutLogin;
