import axios from "axios";

const splinterApi = "https://api2.splinterlands.com/";

export const splinterlandPlayer = (name) => {
  return axios(`${splinterApi}players/balances?username=${name}`);
};

export const getLoginData = (name) => {
  return axios(`https://api2.splinterlands.com/players/v2/update?name=${name}`);
};

export const splinterlandSettings = () => {
  return axios(`${splinterApi}settings`);
};

export const getCardsDetail = () => {
  return axios(`https://api2.splinterlands.com/cards/get_details`);
};

export const splinterlandPlayerData = (name) => {
  return axios(`${splinterApi}players/details?name=${name}`);
};

export const getCardsRentable = (id, edition, isGold) => {
  return axios(
    `https://api2.splinterlands.com/market/for_rent_by_card?card_detail_id=${id}&gold=${isGold}&edition=${edition}&fee=100&v=mQ6mHHh5DwTq4`
  );
};

export const getOneCard = (cardId) => {
  return axios(`https://api2.splinterlands.com/cards/find?ids=${cardId}`);
};

export const rentCard = (data) => {
  return axios.post(`http://localhost:3001/rent-card`, data);
};

export const sendCard = (data) => {
  return axios.post(`http://localhost:3001/move-card`, data);
};

export const sendMoney = (data) => {
  return axios.post(`http://localhost:3001/move-money`, data);
};

export const requestNewQuest = (data) => {
  return axios.post(`http://localhost:3001/new-quest`, data);
};

export const refreshNewQuest = (data) => {
  return axios.post(`http://localhost:3001/refresh-quest`, data);
};

export const findUserQuest = (player) => {
  return axios.get(
    `https://api2.splinterlands.com/players/quests?username=${player}`
  );
};

export const getPlayerCards = (username) => {
  const basicCards = [];
  return fetch(`https://api2.splinterlands.com/cards/collection/${username}`, {
    credentials: "omit",
    headers: { accept: "application/json, text/javascript, */*; q=0.01" },
    referrer: `https://splinterlands.com/?p=collection&a=${username}`,
    referrerPolicy: "no-referrer-when-downgrade",
    body: null,
    method: "GET",
    mode: "cors",
  })
    .then((x) => x && x.json())
    .then((x) =>
      x["cards"]
        ? x["cards"]
            .filter(
              (x) => x.delegated_to === null || x.delegated_to === username
            )
            .map((card) => card.card_detail_id)
        : ""
    )
    .then((advanced) => basicCards.concat(advanced))
    .catch((e) => {
      console.log(
        "Error: game-api.splinterlands did not respond trying api.slinterlands... "
      );
      fetch(`https://api.splinterlands.io/cards/collection/${username}`, {
        credentials: "omit",
        headers: { accept: "application/json, text/javascript, */*; q=0.01" },
        referrer: `https://splinterlands.com/?p=collection&a=${username}`,
        referrerPolicy: "no-referrer-when-downgrade",
        body: null,
        method: "GET",
        mode: "cors",
      })
        .then((x) => x && x.json())
        .then((x) =>
          x["cards"]
            ? x["cards"]
                .filter(
                  (x) => x.delegated_to === null || x.delegated_to === username
                )
                .map((card) => card.card_detail_id)
            : ""
        )
        .then((advanced) => basicCards.concat(advanced))
        .catch((e) => {
          console.log(
            "Using only basic cards due to error when getting user collection from splinterlands: ",
            e
          );
          return basicCards;
        });
    });
};

export const getPlayerCardsWithData = (username) => {
  return fetch(`https://api2.splinterlands.com/cards/collection/${username}`, {
    credentials: "omit",
    headers: { accept: "application/json, text/javascript, */*; q=0.01" },
    referrer: `https://splinterlands.com/?p=collection&a=${username}`,
    referrerPolicy: "no-referrer-when-downgrade",
    body: null,
    method: "GET",
    mode: "cors",
  })
    .then((x) => x && x.json())
    .catch((e) => {
      console.log(
        "Using only basic cards due to error when getting user collection from splinterlands: ",
        e
      );
    });
};
