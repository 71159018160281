import React, { useState, useEffect } from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { useWindowWidth } from "@react-hook/window-size";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import classes from "./SideMenu.module.css";
import * as actionCreator from "../../store/action/index";
import {
  Notification,
  Business,
  Artisan,
  Clients,
  Dashboard,
  Site,
  Settings,
  Articles,
  Selling,
  Disconnect,
} from "../../utils/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getNotification,
  setNotificationRead,
  setNotificationReadBulk,
} from "../../Axios/Call/AuthApi";

const SideMenu = ({ children }) => {
  const [checked, setchecked] = useState(true);
  const [openedTabs, setOpenedTabs] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const clientMode = useSelector((state) => state.dashboard.clientMode);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [news, setNews] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();

  const [menuItems, setMenuItems] = useState([
    {
      id: 0,
      name: "Players",
      redirect: "/players",
      is_active: "players",
    },
    {
      id: 1,
      name: "Cards",
      redirect: "/cards",
      is_active: "cards",
    },
    {
      id: 2,
      name: "Rent",
      redirect: "/rent",
      is_active: "rent",
    },
    {
      id: 3,
      name: "Summoner Combination",
      redirect: "/summoners-combination",
      is_active: "summoners-combination",
    },
    {
      id: 4,
      name: "Create Combination",
      redirect: "/create-combination",
      is_active: "create-combination",
    },
    {
      id: 5,
      name: "Move assets",
      redirect: "/move-cards",
      is_active: "move-cards",
    },
    {
      id: 6,
      name: "Quests",
      redirect: "/quest-refresh",
      is_active: "quest-refresh",
    },
    {
      id: 7,
      name: "Simulator",
      redirect: "/battle-simulator",
      is_active: "battle-simulator",
    },
  ]);
  const width = useWindowWidth();
  const history = useHistory();

  const renderIcon = (redirect) => {
    if (redirect === "/players") {
      return (
        <Dashboard
          fill={location.pathname.includes("players") ? "white" : "#AEB4BE"}
        />
      );
    }
    if (redirect === "/cards") {
      return (
        <Dashboard
          fill={location.pathname.includes("cards") ? "white" : "#AEB4BE"}
        />
      );
    }
    if (redirect === "/rent") {
      return (
        <Dashboard
          fill={location.pathname.includes("rent") ? "white" : "#AEB4BE"}
        />
      );
    }
    if (redirect === "/summoners-combination") {
      return (
        <Dashboard
          fill={
            location.pathname.includes("summoners-combination")
              ? "white"
              : "#AEB4BE"
          }
        />
      );
    }
    if (redirect === "/create-combination") {
      return (
        <Dashboard
          fill={
            location.pathname.includes("create-combination")
              ? "white"
              : "#AEB4BE"
          }
        />
      );
    }
    if (redirect === "/move-cards") {
      return (
        <Dashboard
          fill={location.pathname.includes("move-cards") ? "white" : "#AEB4BE"}
        />
      );
    }
    if (redirect === "/battle-simulator") {
      return (
        <Dashboard
          fill={
            location.pathname.includes("battle-simulator") ? "white" : "#AEB4BE"
          }
        />
      );
    }
  };

  const onClickItemHandler = (item) => {
    if (item?.subSection?.length) {
      if (!openedTabs.find((openTab) => openTab === item.id)) {
        setOpenedTabs(openedTabs.concat(item.id));
      } else {
        setOpenedTabs(openedTabs.filter((openTab) => openTab !== item.id));
      }
    } else {
      history.push(item.redirect);
    }
  };

  const onLogoutHandler = () => {
    dispatch(actionCreator.cleanUserData());
    history.push("/");
  };

  useEffect(() => {
    /* const interval = setInterval(async () => {
      getNotification()
        .then((response) => {
          setNotifications(response.data);
        })
        .catch((error) => {});
    }, 30000); */
    // return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Grid container>
        <Grid
          className={
            checked && width < 600
              ? classes.sideMenuWrapperMobile
              : classes.sideMenuWrapper
          }
          style={{ width: width < 600 ? "100%" : "240px" }}
        >
          <Grid container style={{ margin: "6px 0 30px 0" }}>
            {clientMode && (
              <Grid item xs={10} sm={12} align="center">
                <Typography style={{ color: "white" }}>Mode Client</Typography>
              </Grid>
            )}
            <Grid item xs={2} align="left" justifyContent="center">
              <div
                className={`${
                  !checked && width < 600 ? classes.display : classes.none
                }`}
                onClick={() => setchecked(!checked)}
              >
                <ChevronLeft />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              padding: "0px 10px 0px 10px",
            }}
          >
            {menuItems.map((item) => {
              return (
                <>
                  <Grid xs={12}>
                    <Grid
                      container
                      className={
                        location.pathname.includes(item.is_active)
                          ? classes.itemMenuContainerSelected
                          : classes.itemMenuContainer
                      }
                      onClick={() => onClickItemHandler(item)}
                    >
                      <Grid xs={2} className="my-auto">
                        {renderIcon(item.redirect)}
                      </Grid>
                      <Grid
                        className="my-auto"
                        align="left"
                        style={{ position: "relative" }}
                      >
                        <Typography
                          style={{ cursor: "pointer" }}
                          className={
                            (location.pathname.includes(item.is_active) &&
                              !location.pathname.includes("site-detail")) ||
                            (item.is_active === "site" &&
                              location.pathname.includes("site-detail"))
                              ? classes.isSelectedText
                              : classes.IsNotSelectedText
                          }
                        >
                          {item.name}
                        </Typography>
                        {news[item.news_name] > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "red",
                              width: 13,
                              height: 13,
                              borderRadius: 100,
                              top: -5,
                              right: -15,
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              style={{
                                color: "white",
                                fontSize: 11,
                                fontWeight: "bolder",
                              }}
                            >
                              {news[item.news_name]}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                      {item?.subSection?.length && (
                        <Grid xs={2} className="my-auto">
                          {openedTabs.find((openTab) => openTab === item.id) ? (
                            <KeyboardArrowDownIcon style={{ marginTop: 2 }} />
                          ) : (
                            <ChevronRight style={{ marginTop: 2 }} />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    {openedTabs.find((openTab) => openTab === item.id)
                      ? item.subSection.map((section) => {
                          return (
                            <Grid
                              container
                              className={classes.subMenuContainer}
                            >
                              <Grid xs={2} align="left" />
                              <Grid xs={10} align="left">
                                <Typography
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    history.push(section.redirect);
                                  }}
                                >
                                  {section.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })
                      : null}
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Grid
            xs={12}
            style={{
              position: "absolute",
              bottom: 10,
              left: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <Disconnect />
              <Typography
                onClick={() => onLogoutHandler()}
                style={{
                  color: "white",
                  cursor: "pointer",
                  paddingLeft: 10,
                  marginTop: 2,
                }}
              >
                Déconnexion
              </Typography>
            </div>
          </Grid>
        </Grid>
        {checked && (
          <>
            <Grid //xs={12} sm={8} lg={9}
              style={{ width: width < 600 ? "100%" : "calc(100% - 240px)" }}
            >
              <Grid container className={classes.headContainer}>
                <Grid
                  item
                  xs={width > 600 ? 12 : 9}
                  style={{ paddingRight: 20 }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        cursor: "pointer",
                        position: "relative",
                        paddingLeft: 20,
                        paddingTop: 5,
                      }}
                      onClick={(event) =>
                        setOpenNotification(
                          openNotification
                            ? false
                            : notifications.length
                            ? event.currentTarget
                            : false
                        )
                      }
                    >
                      <Notification fill={"#28395a"} />
                      {notifications.find(
                        (notification) => !notification.is_read
                      ) ? (
                        <div
                          style={{
                            position: "absolute",
                            width: 10,
                            height: 10,
                            backgroundColor: "#FD4953",
                            top: 0,
                            right: 0,
                            borderRadius: 100,
                          }}
                        />
                      ) : null}
                    </div>
                    <div
                      className="my-auto"
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Typography
                        style={{ color: "#28395a", cursor: "pointer" }}
                        onClick={() => history.push("/profile")}
                      >
                        {userData.name} {userData.surname}
                      </Typography>
                    </div>
                    <Avatar
                      src={""}
                      style={{
                        color: "#28395a",
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/profile")}
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={openNotification}
                      keepMounted
                      open={Boolean(openNotification)}
                      onClose={() => {
                        setOpenNotification(false);
                        // setNotificationReadBulk();
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{ marginTop: 30 }}
                    >
                      {notifications?.map((item) => {
                        return (
                          <MenuItem
                            // onClick={() => setNotificationRead(item.id)}
                            style={{
                              whiteSpace: "normal",
                              width: 350,
                              borderBottom: "1px solid #D7D9E1",
                              padding: 15,
                              borderLeft: !item.is_read
                                ? "2px solid #5E35B1"
                                : "",
                            }}
                          >
                            <Typography
                              component="span"
                              style={{
                                cursor: "pointer",
                                color: !item.is_read ? "#2A3254" : "#868CA6",
                              }}
                            >
                              {item.text}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </div>
                </Grid>
                {checked && width < 600 && (
                  <Grid item xs={2} className={classes.box}>
                    <ChevronRight
                      color="white"
                      onClick={() => setchecked(!checked)}
                    />
                  </Grid>
                )}
              </Grid>
              <div
                style={{
                  backgroundColor: "white",
                  minHeight: "calc(100vh - 62px)",
                }}
              >
                {children}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default SideMenu;
