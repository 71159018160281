import React, { useState, useEffect } from "react";
import {
  splinterlandPlayer,
  splinterlandPlayerData,
} from "../../Axios/Call/ApiCall";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import CommonSelect from "../../Components/FormComponents/CommonSelect";
import { Container, Grid, Typography } from "@material-ui/core";
import { getAllPlayers } from "../../Axios/Call/AuthApi";
import SideMenu from "../../Components/Commons/SideMenu";

const PlayerPage = () => {
  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState(null);
  const [playerInformation, setPlayerInformation] = useState([]);
  const [playerOtherInformation, setPlayerOtherInformation] = useState(null);
  const [errorApi, setErrorApi] = useState(false);

  useEffect(() => {
    getAllPlayers()
      .then((response) => {
        setPlayers(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  useEffect(() => {
    if (player) {
      const foundPlayer = players.find((aPlayer) => aPlayer.id == player);
      splinterlandPlayer(foundPlayer.name)
        .then((response) => {
          setPlayerInformation(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
        });

      splinterlandPlayerData(foundPlayer.name)
        .then((response) => {
          setPlayerOtherInformation(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
        });
    }
  }, [player]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      <SideMenu>
        <Container>
          <Grid container style={{ paddingTop: 40 }}>
            <Grid xs={12} md={4}>
              <CommonSelect
                field={{
                  value: player,
                  onChange: (playerCallback) => setPlayer(playerCallback),
                }}
                options={players}
              />
            </Grid>
            {player && (
              <>
                <Grid xs={12} style={{ marginTop: 40 }}>
                  <Typography variant="h2">Informations</Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography variant="h3">
                    {
                      playerInformation?.find(
                        (balance) => balance.token === "DEC"
                      )?.balance
                    }{" "}
                    DEC
                  </Typography>
                  <Typography variant="h3">
                    {playerOtherInformation?.rating} RATING
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default PlayerPage;
