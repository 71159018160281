import "./App.css";
import {
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
} from "react-router-dom";
//----------- Error ------------
//---------- Auth pages ----------
import PlayerPage from "../Containers/PlayerPage/PlayerPage";
import RantingPage from "./RentingPage/RentingPage";
import Login from "./Login/Login";
import LayoutLogin from "../hoc/LayoutLogin";
import CardsPage from "./CardsPage/CardsPage";
import SummonerCombination from "./SummonerCombination/SummonerCombination";
import CreateCombination from "./CreateCombination/CreateCombination";
import MoveCardsPage from "./MoveCardsPage/MoveCardsPage";
import ManageQuest from "./ManageQuest/ManageQuest";
import SimulatorPage from "./SimulatorPage/SimulatorPage";
/* Route */
// eslint-disable-next-line no-unused-vars

const PublicRoute = (props) => <Route {...props} />;
const PrivateRoute = (props) => <Route {...props} />;

const App = () => {
  const history = useHistory();

  return (
    <div className="App">
      <BrowserRouter history={history} basename={"/"}>
        <Switch>
          <PublicRoute path="/" exact>
            <Login />
          </PublicRoute>

          <PrivateRoute path="/rent" exact>
            <LayoutLogin>
              <RantingPage />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/players" exact>
            <LayoutLogin>
              <PlayerPage />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/cards" exact>
            <LayoutLogin>
              <CardsPage />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/summoners-combination" exact>
            <LayoutLogin>
              <SummonerCombination />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/create-combination" exact>
            <LayoutLogin>
              <CreateCombination />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/move-cards" exact>
            <LayoutLogin>
              <MoveCardsPage />
            </LayoutLogin>
          </PrivateRoute>
          <PrivateRoute path="/quest-refresh" exact>
            <LayoutLogin>
              <ManageQuest />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/battle-simulator" exact>
            <LayoutLogin>
              <SimulatorPage />
            </LayoutLogin>
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
