import React, { useEffect, useState } from "react";
import {
  getCardsRentable,
  getOneCard,
  getPlayerCards,
  rentCard,
} from "../../Axios/Call/ApiCall";
import { Card, Container, Grid, Typography } from "@material-ui/core";
import CommonButton from "../../Components/FormComponents/CommonButton";
import SideMenu from "../../Components/Commons/SideMenu";
import {
  getAllPlayers,
  getCombinations,
  getCombinationTypes,
  getOneCombination,
} from "../../Axios/Call/AuthApi";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import CommonSelect from "../../Components/FormComponents/CommonSelect";
import { AiFillDelete, AiFillLock } from "react-icons/ai";
import LoadingButton from "../../Components/Commons/LoadingButton/LoadingButton";
import moment from "moment";
import { FormAddCardToCombo } from "./FormAddCardToCombo";

const RantingPage = () => {
  const [combinationTypes, setCombinationTypes] = useState([]);
  const [choosenCombinationType, setChoosenCombinationType] = useState(null);
  const [combinations, setCombinations] = useState([]);
  const [combinationId, setCombinationId] = useState(false);
  const [choosenCombination, setChoosenCombination] = useState([]);
  const [foundCardsInApi, setFoundCardInApi] = useState([]);
  const [myCards, setMyCards] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState(null);
  const [errorApi, setErrorApi] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [rentingDone, setRentingDone] = useState(false);

  useEffect(() => {
    getAllPlayers()
      .then((response) => {
        setPlayers(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  useEffect(() => {
    if (player) {
      getPlayerCards(
        players.find((onePlayer) => `${onePlayer.id}` === player)?.name
      )
        .then((response) => {
          setMyCards(response);
        })
        .catch((error) => {
          setErrorApi(true);
        });

      getCombinationTypes()
        .then((response) => {
          setCombinationTypes(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
        });
    }
  }, [player]);

  useEffect(() => {
    if (choosenCombinationType)
      getCombinations(choosenCombinationType.id)
        .then((response) => {
          setCombinations(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
        });
  }, [choosenCombinationType]);

  useEffect(() => {
    (async () => {
      if (choosenCombination.length) {
        setLoadingApi(true);
        let cardsFoundObj = [];
        for (const combination of choosenCombination) {
          if (!myCards.find((aCard) => aCard === combination?.card?.id)) {
            const response = await getCardsRentable(
              combination?.card?.uid,
              combination?.card?.edition,
              combination?.is_gold ? true : false
            );
            if (response?.data?.length) {
              let filteredCard = [];
              if (combination?.level !== 1) {
                filteredCard = response.data.filter(
                  (card) => card.xp >= combination?.level /*&&
                    moment(card.last_used_date).isBefore(
                      moment().add(-6, "hours"),
                      "hours"
                    ) &&
                    (!card.last_transferred_date ||
                      moment(card.last_transferred_date).isBefore(
                        moment().add(-1, "days"),
                        "days"
                      ))*/
                );
              } else {
                filteredCard = response.data.filter(
                  (card) => card.xp >= combination?.level || !card.xp /*&&
                    moment(card.last_used_date).isBefore(
                      moment().add(-6, "hours"),
                      "hours"
                    ) &&
                    (!card.last_transferred_date ||
                      moment(card.last_transferred_date).isBefore(
                        moment().add(-1, "days"),
                        "days"
                      )) */
                );
              }

              const foundCards = filteredCard.sort(
                (a, b) => a.buy_price - b.buy_price
              );

              for (const foundCard of foundCards) {
                try {
                  const res = await getOneCard(foundCard.uid);
                  //if(!res.data){
                  //  throw new Error('disastro')
                  //}
                  cardsFoundObj = [...cardsFoundObj, ...res.data];
                  break;
                } catch {}
              }
            }
          }
        }
        setFoundCardInApi(cardsFoundObj);
        setLoadingApi(false);
      }
    })();
  }, [choosenCombination]);

  console.log(
    "foundCard",
    myCards.find((card) => card === 261)
  );

  const handleRentCard = () => {
    setRentingDone(true);
    const mappenMarketId = foundCardsInApi.map((card) => card.market_id);
    let price = 0;
    foundCardsInApi.forEach((card) => {
      price += parseFloat(card.buy_price);
    });

    const choosenPlayer = players.find((onePlayer) => onePlayer.id == player);

    rentCard({
      market_id: mappenMarketId,
      price: price,
      player: choosenPlayer.name,
    })
      .then((response) => {
        setApiSuccess(true);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const onChooseCombination = (combinationId) => {
    getOneCombination(combinationId)
      .then((response) => {
        setChoosenCombination(response.data);
        setCombinationId(combinationId);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const foundACardHandler = (card) => {
    return foundCardsInApi.find(
      (foundCard) => foundCard.card_detail_id === card.id
    );
  };

  const getTotalDecSpent = () => {
    let price = 0;
    foundCardsInApi.forEach((card) => {
      price += parseFloat(card.buy_price);
    });

    return price;
  };

  const getSpellPower = () => {
    let spell_power = 0;
    choosenCombination.forEach((card) => {
      spell_power += card.spell_power;
    });

    return spell_power;
  };

  const onDeleteCardHandler = (cardId) => {
    setFoundCardInApi(
      foundCardsInApi.filter(
        (combination) => combination?.card_detail_id !== cardId
      )
    );
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      {apiSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setApiSuccess}
          description={"Cards rented succesfully"}
        />
      )}
      <SideMenu>
        <Container>
          <Grid container style={{ paddingTop: 40 }}>
            <Grid xs={12} md={4}>
              <CommonSelect
                field={{
                  value: player,
                  onChange: (playerCallback) => setPlayer(playerCallback),
                }}
                options={players}
              />
            </Grid>
            {player && (
              <>
                <Grid xs={12} item>
                  <Grid container style={{ paddingTop: 20 }} spacing={2}>
                    {combinationTypes.map((combination) => {
                      return (
                        <>
                          <Grid xs={12} md={6} item>
                            <Card
                              style={{ padding: 20, cursor: "pointer" }}
                              onClick={() =>
                                setChoosenCombinationType(combination)
                              }
                            >
                              {combination.name}
                            </Card>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid xs={12} item style={{ marginTop: 40 }}>
                  <Grid container style={{ paddingTop: 20 }} spacing={2}>
                    {combinations.map((combination) => {
                      return (
                        <>
                          <Grid xs={12} md={6} item>
                            <Card
                              style={{ padding: 20, cursor: "pointer" }}
                              onClick={() =>
                                onChooseCombination(combination.id)
                              }
                            >
                              <strong style={{ color: "red" }}>
                                {combination.id}
                              </strong>{" "}
                              - {combination.name} -{" "}
                              <strong>{combination.env_name}</strong>-{" "}
                              <strong style={{ color: "green" }}>
                                {combination.max_price}
                              </strong>
                            </Card>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                {loadingApi ? (
                  <LoadingButton />
                ) : (
                  <>
                    <Grid xs={12} item style={{ marginTop: 40 }}>
                      <Grid container spacing={2}>
                        {choosenCombination.map((combination) => {
                          return (
                            <Grid xs={6} md={3} item>
                              <Grid container spacing={2}>
                                <Grid xs={12}>
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={combination?.card?.picture}
                                      style={{
                                        width: "40%",
                                        border: combination?.is_gold
                                          ? "4px solid #FFD700"
                                          : "",
                                      }}
                                      alt="a card"
                                    />
                                    {!myCards.find(
                                      (aCard) => aCard === combination?.card?.id
                                    ) ? (
                                      <AiFillDelete
                                        color="red"
                                        style={{
                                          position: "absolute",
                                          top: -10,
                                          cursor: "pointer",
                                        }}
                                        size={25}
                                        onClick={() =>
                                          onDeleteCardHandler(
                                            combination?.card?.id
                                          )
                                        }
                                      />
                                    ) : (
                                      <AiFillLock
                                        color="green"
                                        style={{
                                          position: "absolute",
                                          top: -10,
                                        }}
                                        size={25}
                                      />
                                    )}
                                  </div>
                                </Grid>
                                <Grid xs={12} item>
                                  <Typography>
                                    {combination?.card?.uid}
                                    {" - "}
                                    {combination?.card?.name}
                                    {" - "}
                                    {combination?.level}
                                    {" - "}
                                    {combination?.spell_power}
                                  </Typography>

                                  {foundCardsInApi?.length > 0 &&
                                    foundACardHandler(combination?.card) && (
                                      <Typography style={{ color: "green" }}>
                                        {
                                          foundCardsInApi.find(
                                            (foundCard) =>
                                              foundCard.card_detail_id ===
                                              combination.card.id
                                          )?.buy_price
                                        }{" "}
                                        DEC
                                      </Typography>
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                    {/*foundCardsInApi?.length > 0 && !rentingDone && (
                      <Grid xs={12} md={6} item style={{ marginTop: 40 }}>
                        <CommonButton
                          text={`Rent card: ${getTotalDecSpent()} DEC - ${getSpellPower()} spellPower`}
                          onClick={() => handleRentCard()}
                        />
                      </Grid>
                    )*/}
                    {combinationId && <FormAddCardToCombo id={combinationId} />}
                  </>
                )}
              </>
            )}
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default RantingPage;
