import React, { useState, useEffect } from "react";
import SideMenu from "../../Components/Commons/SideMenu";
import { Card, Container, Grid, Typography } from "@material-ui/core";
import { getSummoners } from "../../Axios/Call/AuthApi";

const SummonerCombination = () => {
  const [summoners, setSummoners] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  useEffect(() => {
    getSummoners()
      .then((response) => {
        setSummoners(summoners);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <SideMenu>
        <Container>
          <Grid container style={{ paddingTop: 40 }}>
            <Grid xs={12} item>
              <Typography>Summoners</Typography>
            </Grid>
            <Grid xs={12} item style={{ marginTop: 40 }}></Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default SummonerCombination;
