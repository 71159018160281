import React from "react";
import SideMenu from "../../Components/Commons/SideMenu";
import { Card, Container, Grid, Typography } from "@material-ui/core";

const CreateCombination = () => {
  return (
    <>
      <SideMenu>
        <Container>
          <Grid container style={{ paddingTop: 40 }}></Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default CreateCombination;
