import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import { formatErrorEmail } from "../../utils/formValidation";
import CommonButton from "../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import { login } from "../../Axios/Call/ClientCredential";
import * as actionCreator from "../../store/action/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./Login.module.css";

const Login = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailConfirmationError, setEmailConfirmationError] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    login(data)
      .then((response) => {
        localStorage.setItem("token", "Bearer " + response.data.token);
        dispatch(actionCreator.populateUserData());
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.email_verified_at) {
        history.push("/players");
      } else {
        setEmailConfirmationError(true);
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [userData]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore API"}
        />
      )}
      {emailConfirmationError && (
        <AlertGeneral
          color="danger"
          setTrigger={setEmailConfirmationError}
          description={"ERRORE LOGIN"}
        />
      )}
      <div style={{ backgroundColor: "white" }}>
        <Container style={{ maxWidth: "none", paddingRight: "0px" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8} style={{ backgroundColor: "white" }}>
              <form
                onSubmit={handleSubmit(sendFormLogin)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={5}>
                    <Grid container>
                      <Grid item xs={12} align="left">
                        <Typography variant="h1">{"Accedi"}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 30 }}>
                        <TextFieldCustom
                          label={"Email"}
                          type="email"
                          ref={register({
                            required: "Campo richiesto",
                            validate: {
                              formatErrorEmail,
                            },
                          })}
                          defaultValue=""
                          name="email"
                          error={errors.email}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <TextFieldCustom
                          variant="outlined"
                          label={"Password"}
                          type="password"
                          ref={register({ required: "Campo richiesto" })}
                          defaultValue=""
                          name={"password"}
                          error={errors.password}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <CommonButton loading={loadingApi} text={"ACCEDI"} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <div style={{ position: "absolute", bottom: 10, left: 10 }}>
                <Typography style={{ color: "#AEB4BE" }}>
                  © 2021 InfoRenov
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Login;
