import axios from "axios";

const api = "https://api-splinterlands.manciomarket.com/api/";

export const clientCredentialAxios = axios.create({
  baseURL: (axios.defaults.baseURL = api),
});

export const authApiAxios = axios.create({
  baseURL: (axios.defaults.baseURL = api),
});

authApiAxios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");
//https://api-splinterlands.manciomarket.com/api/
//http://127.0.0.1:8000/api/
