import { authApiAxios } from "../AxiosInstance";

export const me = () => {
  return authApiAxios.get("me");
};

export const getAllPlayers = (group) => {
  return authApiAxios.get(`players${group ? `?group=${group}` : ""}`);
};

//-------------- Cards Api --------------------

export const getCards = () => {
  return authApiAxios.get("cards");
};

export const getOneCard = (id) => {
  return authApiAxios.get(`card/${id}`);
};

export const editCard = (id, data) => {
  return authApiAxios.patch(`card/${id}`, data);
};

export const createCard = (data) => {
  return authApiAxios.post("card", data);
};

export const deleteCard = (id) => {
  return authApiAxios.delete(`card/${id}`);
};

//-------------- COmbination api -----------------------

export const getCombinationTypes = () => {
  return authApiAxios.get(`combination-types`);
};

export const getCombinations = (combinationTypeId) => {
  return authApiAxios.get(`combinations/${combinationTypeId}`);
};

export const getOneCombination = (id) => {
  return authApiAxios.get(`combination/${id}`);
};

export const getSummoners = () => {
  return authApiAxios.get(`summoners`);
};

export const getNextGameType = (last_game_id_played) => {
  return authApiAxios.get(
    `/next-game-type?last_game_id_played=${last_game_id_played}`
  );
};

export const getOneSummoner = (summonerId) => {
  return authApiAxios.get(`summoner/${summonerId}`);
};

export const increaseBattleTypeId = (summonerId) => {
  return authApiAxios.get(`summoner/${summonerId}/increase-game-type`);
};

export const createGame = (data) => {
  return authApiAxios.post(`game`, data);
};

export const copyEntireCombination = (gameTypeId, summonerId) => {
  return authApiAxios.get(`/copy/${gameTypeId}/summoner/${summonerId}`);
};

//--------------- Notifications ------------------------
export const getNotification = (id) => {
  // return authApiAxios.get(`notifications`);
  return true;
};

export const setNotificationRead = (id) => {
  // return authApiAxios.get(`notification/${id}/is-read`);
  return true;
};

export const setNotificationReadBulk = () => {
  // return authApiAxios.get(`notifications/is-read`);
  return true;
};

export const getSettings = () => {
  return authApiAxios.get(`/setting`);
};

export const getBestTeamPossible = ({
  mana,
  ruleset,
  mappedSplinter,
  combinationChoosen,
  questToPrioritize,
}) => {
  return authApiAxios.get(
    `get-team?mana_cap=${mana}&ruleset=${ruleset}&summoners=${mappedSplinter}&combination=${combinationChoosen}&token=stocazzo&questToPrioritize=${questToPrioritize}`
  );
};

export const getBestTeamPossibleV2 = ({
  mana,
  ruleset,
  mappedSplinter,
  questToPrioritize,
}) => {
  return authApiAxios.post(`get-team-v2`, {
    mana_cap: mana,
    summoners: JSON.stringify(mappedSplinter),
    ruleset: ruleset,
    token: "stocazzo",
    questToPrioritize,
    rating: 1200,
  });
};

export const addCardToCombination = ({ combination_id, card_id, level }) => {
  return authApiAxios.post(`add-card-to-combination`, {
    combination_id,
    card_id,
    level,
  });
};

export const addCardToSummonerCombination = ({ summoner_id, card_id }) => {
  return authApiAxios.post(`create-summoner-combination`, {
    summoner_id,
    card_id,
  });
};
