import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import text from "../../utils/text";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import { addCardToSummonerCombination } from "../../Axios/Call/AuthApi";
import AlertGeneral from "../Commons/AlertGeneral/AlertGeneral";
import CommonButton from "../FormComponents/CommonButton";

export const FormAddCard = ({ id }) => {
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [successApi, setSuccessApi] = useState(false);
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const addCard = (data) => {
    setLoadingApiAdd(true);
    addCardToSummonerCombination({ summoner_id: id, card_id: data.card_id })
      .then((response) => {
        setSuccessApi(true);
        setLoadingApiAdd(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApiAdd(false);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      {successApi && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccessApi}
          description={"Aggiunta con successo"}
        />
      )}
      <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
        <form
          onSubmit={handleSubmit(addCard)}
          style={{ marginTop: 20 }}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
              <TextFieldCustom
                variant="outlined"
                label={"CARD ID"}
                type="input"
                ref={register({ required: text.General.required })}
                defaultValue=""
                name={"card_id"}
                error={errors.card_id}
              />
            </Grid>
            <Grid>
              <CommonButton loading={loadingApiAdd} text={"ADD"} />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
