import { InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const FilterTextList = ({ setFiltered, allItems }) => {
  const [researchValue, setResearchValue] = useState("");

  useEffect(() => {
    if (researchValue && allItems.length) {
      const businessFiltered = [];
      let isChoosen = false;
      allItems.forEach((item) => {
        isChoosen = false;
        Object.keys(item).forEach((key) => {
          if (
            item[key]?.toString()?.toLowerCase()?.includes(researchValue) &&
            !isChoosen
          ) {
            isChoosen = true;
            businessFiltered.push(item);
          }
        });
      });

      setFiltered(businessFiltered);
    } else {
      if (allItems.length) {
        setFiltered(allItems);
      }
    }
  }, [researchValue]);

  return (
    <>
      <TextField
        variant="outlined"
        name={"research"}
        type={"input"}
        placeholder={"Rechercher par nom, email, date..."}
        fullWidth
        value={researchValue}
        onChange={(event) => setResearchValue(event.target.value)}
        size="small"
      />
    </>
  );
};

export default FilterTextList;
