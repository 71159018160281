import React from "react";
import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  input: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontSize: 14,
  },
});

const CommonSelect = ({ field, label, options, name, error, labelCustom }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        {labelCustom && (
          <Grid items xs={12} align="left">
            <Typography component="span" className={classes.label}>
              {labelCustom}
            </Typography>
          </Grid>
        )}
        <Grid items xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              {label}
            </InputLabel>
            <Select
              native
              value={field.value}
              onChange={(event) => field.onChange(event.target.value)}
              variant="outlined"
              label={label}
              name={name}
              error={!!error}
              className={classes.input}
            >
              <option value=""></option>
              {options.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid items xs={12}>
          <Typography component="span" style={{ color: "red", fontSize: 12 }}>
            {error?.message}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonSelect;
