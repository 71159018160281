import { useEffect, useState } from "react";
import SideMenu from "../../Components/Commons/SideMenu";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from "@material-ui/core";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import text from "../../utils/text";
import { withStyles } from "@material-ui/core/styles";
import { Articles } from "../../utils/icons";
import CommonButton from "../../Components/FormComponents/CommonButton";
import LoadingButton from "../../Components/Commons/LoadingButton/LoadingButton";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import FilterTextList from "../../Components/Commons/FilterTextList/FilterTextList";
import { useHistory } from "react-router-dom";
import {
  createCard,
  editCard,
  getCards,
  deleteCard,
  getOneCard,
} from "../../Axios/Call/AuthApi";
import ModalAddCard from "./ModalAddCard";

const SupplierList = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [editSupplier, setEditSupplier] = useState({});
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");

  const history = useHistory();

  useEffect(() => {
    setLoadingApi(true);
    populateSuppliers();
  }, []);

  const populateSuppliers = () => {
    getCards()
      .then((response) => {
        setSuppliers(response.data);
        setFilteredSuppliers(response.data);
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#28395A",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#28395A",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  const onDeleteArticle = (openModalDelete) => {
    deleteCard(openModalDelete)
      .then((response) => {
        setSuppliers(
          suppliers.filter((supplier) => supplier.id !== openModalDelete)
        );
        populateSuppliers();
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const onCreateSupplier = (data) => {
    setLoadingApiAdd(true);
    if (Object.keys(editSupplier).length) {
      editCard(editSupplier.id, data)
        .then((response) => {
          setLoadingApiAdd(false);
          setOpenModalAdd(false);
          setEditSupplier({});
          populateSuppliers();
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          setErrorApi(true);
        });
    } else {
      createCard(data)
        .then((response) => {
          setLoadingApiAdd(false);
          setOpenModalAdd(false);
          populateSuppliers();
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          setErrorApi(true);
        });
    }
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <ModalAddCard
        setOpenModalAdd={setOpenModalAdd}
        openModalAdd={openModalAdd}
        onCreateSupplier={onCreateSupplier}
        loadingApiAdd={loadingApiAdd}
        editSupplier={editSupplier}
        setEditSupplier={setEditSupplier}
        setErrorApi={setErrorApi}
      />
      <SideMenu>
        <Container style={{ maxWidth: "none" }}>
          <Grid container>
            <Grid item align="left" style={{ paddingTop: 5 }}>
              <Articles size={38} fill="#28395A" />
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 10 }}>
              <Typography
                variant="h2"
                style={{ cursor: "pointer", color: "#AEB4BE" }}
                onClick={() => history.push("articles")}
              >
                {"All Cards"}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  <FilterTextList
                    allItems={suppliers}
                    setFiltered={setFilteredSuppliers}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  <CommonButton
                    type="button"
                    text={"Create a card"}
                    onClick={() => setOpenModalAdd(true)}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: "100%" }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "uid"}
                                direction={orderBy === "uid" ? order : "asc"}
                                onClick={createSortHandler("uid")}
                              >
                                {"UID"}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {"Name"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {"Picture"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.ArticleList.action}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            filteredSuppliers,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((supplier) => (
                              <StyledTableRow key={supplier.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography component="span">
                                    {supplier?.uid}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${supplier.name}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <img
                                    src={supplier.picture}
                                    alt="img"
                                    style={{ width: "20%" }}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AiFillEdit
                                      size={24}
                                      color="#28395A"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        getOneCard(supplier.id)
                                          .then((response) => {
                                            setEditSupplier(response.data);
                                            setOpenModalAdd(true);
                                          })
                                          .catch((error) => {
                                            setErrorApi(true);
                                          });
                                      }}
                                    />
                                    <AiFillDelete
                                      size={24}
                                      color="#900C3F"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        onDeleteArticle(supplier.id)
                                      }
                                    />
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={suppliers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default SupplierList;
