import { Grid, Container, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  findUserQuest,
  refreshNewQuest,
  requestNewQuest,
} from "../../Axios/Call/ApiCall";
import { getAllPlayers, getSettings } from "../../Axios/Call/AuthApi";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../Components/Commons/LoadingButton/LoadingButton";
import SideMenu from "../../Components/Commons/SideMenu";
import CommonButton from "../../Components/FormComponents/CommonButton";

const ManageQuest = () => {
  const [players, setPlayers] = useState([]);
  const [settings, setSettings] = useState(null);
  const [loadingPlayer, setLoadingPlayer] = useState(false);
  const [loadingNewQuest, setLoadingNewQuest] = useState(false);
  const [playersWithQuest, setPlayersWithQuest] = useState([]);
  const [playersWithNoGoodQuest, setPlayerWithNoGoodQuest] = useState([]);
  const [errorApi, setErrorApi] = useState(false);
  const [successRefreshQuest, setSuccessRefreshQuest] = useState(false);

  useEffect(() => {
    getAllPlayers()
      .then((response) => {
        getSettings().then((settingsResponse) => {
          setSettings(settingsResponse?.value);
          setPlayers(response.data.filter((player) => player?.group));
        });
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  console.log(settings);

  useEffect(() => {
    (async () => {
      if (players.length) {
        setLoadingPlayer(true);
        const playerWithQuestToRefresh = [];
        const playerWithNoGoodQuestQuestToRefresh = [];
        for (const player of players) {
          const { data } = await findUserQuest(player.name);
          if (
            //data[0]?.claim_date &&
            //moment(data[0]?.claim_date).add(100, "days") > moment() &&
            //moment(data[0]?.claim_date).format("dd") !== moment().format("dd")
            data[0].claim_date &&
            data[0]?.created_date &&
            moment().diff(moment(data[0]?.created_date), "minutes") >= 1441
          )
            playerWithQuestToRefresh.push({
              ...player,
              questName: data[0].name,
            });

          if (data[0]?.name === "gloridax" || data[0]?.name === "rising") {
            playerWithNoGoodQuestQuestToRefresh.push({
              ...player,
              questName: data[0].name,
            });
          }
        }
        setPlayersWithQuest(playerWithQuestToRefresh);
        setPlayerWithNoGoodQuest(playerWithNoGoodQuestQuestToRefresh);
        setLoadingPlayer(false);
      }
    })();
  }, [players]);

  useEffect(() => {
    if (successRefreshQuest) {
      (async () => {
        if (playersWithQuest.length) {
          let playerWithQuestToRefresh = playersWithQuest;
          for (const player of playersWithQuest) {
            const { data } = await findUserQuest(player.name);
            if (!data[0].claim_date)
              playerWithQuestToRefresh = playerWithQuestToRefresh.map((item) =>
                item.name === player.name
                  ? { ...item, newQuestFound: data[0].name }
                  : { ...item }
              );
          }
          setPlayersWithQuest(playerWithQuestToRefresh);
        }

        if (playersWithNoGoodQuest.length) {
          let playersWithNoGoodQuestNew = playersWithNoGoodQuest;
          for (const player of playersWithNoGoodQuest) {
            const { data } = await findUserQuest(player.name);
            if (
              !data[0].name === "defend" &&
              !data[0].name === "gloridax" &&
              !player.combination
            )
              playersWithNoGoodQuestNew = playersWithNoGoodQuestNew.map(
                (item) =>
                  item.name === player.name
                    ? { ...item, newQuestFound: data[0].name }
                    : { ...item }
              );
          }
          setPlayerWithNoGoodQuest(playersWithNoGoodQuestNew);
        }
      })();

      setLoadingNewQuest(false);
      setSuccessRefreshQuest(false);
    }
  }, [successRefreshQuest]);

  const refreshQuestHandler = async () => {
    setLoadingNewQuest(true);
    await requestNewQuest({
      players: playersWithQuest.map((item) => item.name),
    });

    setSuccessRefreshQuest(true);
  };

  console.log("successRefreshQuest", successRefreshQuest);

  const getNewQuestHandler = async () => {
    setLoadingNewQuest(true);
    await refreshNewQuest({
      players: playersWithNoGoodQuest.map((item) => item.name),
    });

    setSuccessRefreshQuest(true);
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      <SideMenu>
        <Container>
          <Grid container style={{ paddingTop: 40 }}>
            {loadingPlayer ? (
              <LoadingButton />
            ) : (
              <>
                {playersWithQuest.map((playerQuest) => {
                  return (
                    <Grid xs={12} md={4}>
                      <Typography>
                        <strong>{playerQuest.name}</strong> - Quest to refresh:{" "}
                        {playerQuest.questName} {"  "} - new Quest:{" "}
                        {playerQuest.newQuestFound}
                      </Typography>
                    </Grid>
                  );
                })}

                <Grid xs={12} style={{ marginBottom: 50 }}>
                  <CommonButton
                    text={"GET new Quest"}
                    onClick={() => refreshQuestHandler()}
                    loading={loadingNewQuest}
                  />
                </Grid>

                {playersWithNoGoodQuest.map((playerQuest) => {
                  return (
                    <Grid xs={12} md={4}>
                      <Typography>
                        <strong>{playerQuest.name}</strong> - Quest to refresh:{" "}
                        {playerQuest.questName} {"  "} - new Quest:{" "}
                        {playerQuest.newQuestFound}
                      </Typography>
                    </Grid>
                  );
                })}

                <Grid xs={12}>
                  <CommonButton
                    text={"Refresh Quest"}
                    onClick={() => getNewQuestHandler()}
                    loading={loadingNewQuest}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default ManageQuest;
