import React, { useEffect, useState } from "react";
import { getOneSummoner, getSummoners } from "../../Axios/Call/AuthApi";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../Components/Commons/LoadingButton/LoadingButton";
import SideMenu from "../../Components/Commons/SideMenu";
import { Grid, Container, Typography, Card } from "@material-ui/core";
import SimulationSection from "../../Components/SimulatorPage/SimulationSection";
import { getCardsDetail, splinterlandSettings } from "../../Axios/Call/ApiCall";

const SimulatorPage = () => {
  const [summoners, setSummoners] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [cardsDetail, setCardsDetail] = useState([]);
  const [settings, setSettings] = useState(undefined);
  const [summonerCombination, setSummonerCombination] = useState({});

  useEffect(() => {
    setLoadingApi(true);
    getSummoners()
      .then((response) => {
        setSummoners(response.data);
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, []);

  const pickSummonerHandler = (summonerId) => {
    setLoadingApi(true);
    getOneSummoner(summonerId)
      .then((response) => {
        setLoadingApi(false);
        setSummonerCombination(response.data);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  useEffect(() => {
    getCardsDetail()
      .then((response) => {
        setCardsDetail(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  useEffect(() => {
    splinterlandSettings()
      .then((response) => {
        setSettings(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      <SideMenu>
        <Container>
          {!Object.keys(summonerCombination).length ? (
            <Grid container>
              <Grid xs={12} md={12} style={{ marginBottom: 40 }}>
                <Typography variant="h5">
                  Please choose a lord to start simulation
                </Typography>
              </Grid>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <>
                  {summoners.map((summoner) => {
                    return (
                      <Grid xs={12} md={3}>
                        <Card
                          elevation={5}
                          style={{ cursor: "pointer" }}
                          onClick={() => pickSummonerHandler(summoner.id)}
                        >
                          <Grid container>
                            <Grid xs={12}>
                              <Typography>{summoner.name}</Typography>
                            </Grid>
                            <Grid
                              xs={12}
                              style={{ marginTop: 10, marginBottom: 10 }}
                            >
                              <img
                                src={summoner.picture_path}
                                alt={summoner.name}
                                style={{ width: "50%" }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          ) : (
            <SimulationSection
              summonerCombination={summonerCombination}
              cardsDetail={cardsDetail}
              settings={settings}
            />
          )}
        </Container>
      </SideMenu>
    </>
  );
};

export default SimulatorPage;
