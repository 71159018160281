import React, { useEffect, useState } from "react";
import { getAllPlayers } from "../../Axios/Call/AuthApi";
import SideMenu from "../../Components/Commons/SideMenu";
import { Card, Container, Grid, Typography } from "@material-ui/core";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import {
  getPlayerCardsWithData,
  sendCard,
  sendMoney,
  splinterlandPlayer,
  splinterlandPlayerData,
  splinterlandSettings,
} from "../../Axios/Call/ApiCall";
import CommonButton from "../../Components/FormComponents/CommonButton";
import CommonSelect from "../../Components/FormComponents/CommonSelect";

const MoveCardsPage = () => {
  const [buildGroups, setBuildGroups] = useState(null);
  const [group, setGroup] = useState(null);
  const [machine, setMachine] = useState(1);
  const [players, setPlayers] = useState([]);
  const [balances, setBalances] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [choosenPlayer, setChoosenPlayer] = useState(null);
  const [cardsIds, setCardsIds] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [settings, setSettings] = useState(null);
  const [errorApi, setErrorApi] = useState(false);

  useEffect(() => {
    let groups = [];
    for (let i = 400; i <= 572; i++) {
      groups.push({ id: i, name: `group${i}` });
    }

    groups.push({ id: 1000, name: `group1000` });
    groups.push({ id: 1001, name: `group1001` });
    groups.push({ id: 1002, name: `group1002` });
    groups.push({ id: 1003, name: `group1003` });

    setBuildGroups(groups);
  }, []);

  useEffect(() => {
    setPlayers([]);
    splinterlandSettings()
      .then((response) => {
        setSettings(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
    (async () => {
      if (group) {
        //get all player by group
        setLoadingApi(true);
        getAllPlayers(group)
          .then(async (response) => {
            let foundPlayers = response.data;
            console.log(parseInt(machine));
            foundPlayers = foundPlayers.filter(
              (player) => player.machine_id === parseInt(machine)
            );
            console.log(foundPlayers);
            const newPlayer = [];
            for (const foundPlayer of foundPlayers) {
              const splinterlandPlayerInfo = await splinterlandPlayer(
                foundPlayer.name
              );

              const splinterlandOtherInfo = await splinterlandPlayerData(
                foundPlayer.name
              );

              newPlayer.push({
                ...foundPlayer,
                balances: splinterlandPlayerInfo.data,
                splinterlandInfo: { ...splinterlandOtherInfo.data },
              });
            }
            setPlayers(newPlayer);
            setLoadingApi(false);
          })
          .catch((error) => {
            setErrorApi(true);
            setLoadingApi(false);
          });
      }
    })();
  }, [group]);

  console.log("players", players);

  useEffect(() => {
    if (choosenPlayer) {
      getPlayerCardsWithData(choosenPlayer.name)
        .then((response) => {
          const cardsThatIOwn = response?.cards?.filter(
            (card) => card.player === choosenPlayer.name
          );
          setCardsIds(cardsThatIOwn.map((card) => card.uid));
          splinterlandPlayer(choosenPlayer.name)
            .then((response) => {
              setBalances(response.data);
            })
            .catch((error) => {
              console.log(error);
              setErrorApi(true);
            });
        })
        .catch((error) => {
          console.log(error);
          setErrorApi(true);
        });
    }
  }, [choosenPlayer]);

  const onTransferCardCall = () => {
    sendCard({
      from_player: choosenPlayer.name,
      to_player: "ajeje",
      cardsArrayId: cardsIds,
    })
      .then((response) => {
        setApiSuccess(true);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const onTransferCredit = () => {
    const decBalance = balances.find(
      (balance) => balance.token === "DEC"
    )?.balance;
    if (decBalance > 1000) {
      sendMoney({
        from_player: choosenPlayer.name,
        to_player: "ajeje",
        dec: decBalance - 1000,
        currency: "DEC",
      })
        .then((response) => {
          setApiSuccess(true);
        })
        .catch((error) => {
          setErrorApi(true);
        });
    }
  };

  const onTransferCreditSps = () => {
    const spsBalance = balances.find(
      (balance) => balance.token === "SPS"
    )?.balance;
    if (spsBalance > 0) {
      sendMoney({
        from_player: choosenPlayer.name,
        to_player: "ajeje",
        dec: spsBalance,
        currency: "SPS",
      })
        .then((response) => {
          setApiSuccess(true);
        })
        .catch((error) => {
          setErrorApi(true);
        });
    }
  };

  const renderDec = (player) => {
    return player?.balances?.find((balance) => balance.token === "DEC")
      ?.balance;
  };

  const renderCurrency = (player) => {
    return player?.balances?.find((balance) => balance.token === "CREDITS")
      ?.balance;
  };

  const renderMerits = (player) => {
    return player?.balances?.find((balance) => balance.token === "MERITS")
      ?.balance;
  };

  const calculateECR = (
    captureRate,
    hourlyRegenRate,
    lastRewardTime,
    maxEnergy
  ) => {
    console.log("captureRate", captureRate);
    console.log("hourlyRegenRate", hourlyRegenRate);
    console.log("lastRewardTime", lastRewardTime);
    console.log("maxEnergy", maxEnergy);
    const captureRateParsed = parseFloat(`${captureRate}`);
    const initialEnergy = isNaN(captureRateParsed)
      ? maxEnergy
      : captureRateParsed;
    const timeSinceLastRewardMs =
      Date.now() - new Date(lastRewardTime).getTime();
    const regeneratedEnergy =
      (timeSinceLastRewardMs / (1000 * 60 * 60)) * hourlyRegenRate;
    const newEnergy = initialEnergy + regeneratedEnergy;
    // can't regenerate more energy than max amount
    const limitedNewEnergy = Math.min(newEnergy, maxEnergy);
    return limitedNewEnergy;
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      {apiSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setApiSuccess}
          description={"Cards rented succesfully"}
        />
      )}
      <SideMenu>
        <Container>
          <Grid container style={{ paddingTop: 40 }}>
            <Grid xs={6}>
              <CommonSelect
                field={{
                  value: group,
                  onChange: (playerCallback) => setGroup(playerCallback),
                }}
                options={buildGroups || []}
              />
            </Grid>
            <Grid xs={4}>
              <CommonSelect
                field={{
                  value: machine,
                  onChange: (playerCallback) => setMachine(playerCallback),
                }}
                options={[
                  { id: 1, name: "machine id 1" },
                  { id: 2, name: "machine id 2" },
                  { id: 3, name: "machine id 3" },
                  { id: 4, name: "machine id 4" },
                  { id: 5, name: "machine id 5" },
                  { id: 6, name: "machine id 6" },
                  { id: 7, name: "machine id 7" },
                  { id: 8, name: "machine id 8" },
                  { id: 9, name: "machine id 9" },
                  { id: 10, name: "machine id 10" },
                  { id: 11, name: "machine id 11" },
                  { id: 12, name: "machine id 12" },
                  { id: 13, name: "machine id 13" },
                  { id: 14, name: "machine id 14" },
                  { id: 15, name: "machine id 15" },
                  { id: 16, name: "machine id 16" },
                  { id: 17, name: "machine id 17" },
                  { id: 18, name: "machine id 18" },
                  { id: 19, name: "machine id 19" },
                  { id: 20, name: "machine id 20" },
                  { id: 21, name: "machine id 21" },
                  { id: 22, name: "machine id 22" },
                  { id: 23, name: "machine id 23" },
                  { id: 24, name: "machine id 24" },
                  { id: 25, name: "machine id 25" },
                  { id: 26, name: "machine id 26" },
                  { id: 27, name: "machine id 27" },
                  { id: 28, name: "machine id 28" },
                  { id: 29, name: "machine id 29" },
                  { id: 30, name: "machine id 30" },
                  { id: 31, name: "machine id 31" },
                  { id: 32, name: "machine id 32" },
                  { id: 33, name: "machine id 33" },
                  { id: 34, name: "machine id 34" },
                  { id: 35, name: "machine id 35" },
                  { id: 36, name: "machine id 36" },
                  { id: 37, name: "machine id 37" },
                  { id: 38, name: "machine id 38" },
                  { id: 39, name: "machine id 39" },
                  { id: 40, name: "machine id 40" },
                  { id: 41, name: "machine id 41" },
                  { id: 42, name: "machine id 42" },
                  { id: 43, name: "machine id 43" },
                  { id: 44, name: "machine id 44" },
                  { id: 45, name: "machine id 45" },
                  { id: 46, name: "machine id 46" },
                  { id: 47, name: "machine id 47" },
                  { id: 48, name: "machine id 48" },
                  { id: 49, name: "machine id 49" },
                  { id: 50, name: "machine id 50" },
                  { id: 51, name: "machine id 51" },
                  { id: 52, name: "machine id 52" },
                  { id: 53, name: "machine id 53" },
                  { id: 54, name: "machine id 54" },
                  { id: 55, name: "machine id 55" },
                  { id: 56, name: "machine id 56" },
                  { id: 57, name: "machine id 57" },
                  { id: 58, name: "machine id 58" },
                  { id: 59, name: "machine id 59" },
                  { id: 60, name: "machine id 60" },
                  { id: 61, name: "machine id 61" },
                  { id: 62, name: "machine id 62" },
                  { id: 63, name: "machine id 63" },
                  { id: 64, name: "machine id 64" },
                  { id: 65, name: "machine id 65" },
                  { id: 66, name: "machine id 66" },
                  { id: 67, name: "machine id 67" },
                  { id: 68, name: "machine id 68" },
                  { id: 69, name: "machine id 69" },
                  { id: 70, name: "machine id 70" },
                  { id: 71, name: "machine id 71" },
                  { id: 72, name: "machine id 72" },
                  { id: 73, name: "machine id 73" },
                  { id: 74, name: "machine id 74" },
                  { id: 75, name: "machine id 75" },
                  { id: 76, name: "machine id 76" },
                  { id: 77, name: "machine id 77" },
                  { id: 78, name: "machine id 78" },
                  { id: 79, name: "machine id 79" },
                  { id: 80, name: "machine id 80" },
                  { id: 81, name: "machine id 81" },
                  { id: 82, name: "machine id 82" },
                  { id: 83, name: "machine id 83" },
                  { id: 84, name: "machine id 84" },
                  { id: 85, name: "machine id 85" },
                  { id: 86, name: "machine id 86" },
                  { id: 87, name: "machine id 87" },
                  { id: 88, name: "machine id 88" },
                  { id: 89, name: "machine id 89" },
                  { id: 90, name: "machine id 90" },
                  { id: 91, name: "machine id 91" },
                  { id: 92, name: "machine id 92" },
                  { id: 93, name: "machine id 93" },
                  { id: 94, name: "machine id 94" },
                  { id: 95, name: "machine id 95" },
                  { id: 96, name: "machine id 96" },
                  { id: 97, name: "machine id 97" },
                  { id: 98, name: "machine id 98" },
                  { id: 99, name: "machine id 99" },
                  { id: 100, name: "machine id 100" },
                  { id: 101, name: "machine id 101" },
                  { id: 102, name: "machine id 102" },
                  { id: 103, name: "machine id 103" },
                  { id: 104, name: "machine id 104" },
                  { id: 105, name: "machine id 105" },
                  { id: 106, name: "machine id 106" },
                  { id: 107, name: "machine id 107" },
                  { id: 108, name: "machine id 108" },
                  { id: 109, name: "machine id 109" },
                  { id: 110, name: "machine id 110" },
                  { id: 111, name: "machine id 111" },
                  { id: 112, name: "machine id 112" },
                  { id: 113, name: "machine id 113" },
                  { id: 114, name: "machine id 114" },
                  { id: 115, name: "machine id 115" },
                  { id: 116, name: "machine id 116" },
                  { id: 117, name: "machine id 117" },
                  { id: 118, name: "machine id 118" },
                  { id: 119, name: "machine id 119" },
                  { id: 120, name: "machine id 120" },
                  { id: 121, name: "machine id 121" },
                  { id: 122, name: "machine id 122" },
                  { id: 123, name: "machine id 123" },
                  { id: 124, name: "machine id 124" },
                  { id: 125, name: "machine id 125" },
                  { id: 126, name: "machine id 126" },
                  { id: 127, name: "machine id 127" },
                  { id: 128, name: "machine id 128" },
                  { id: 129, name: "machine id 129" },
                  { id: 130, name: "machine id 130" },
                  { id: 131, name: "machine id 131" },
                  { id: 132, name: "machine id 132" },
                  { id: 133, name: "machine id 133" },
                  { id: 134, name: "machine id 134" },
                  { id: 135, name: "machine id 135" },
                  { id: 136, name: "machine id 136" },
                  { id: 137, name: "machine id 137" },
                  { id: 138, name: "machine id 138" },
                  { id: 139, name: "machine id 139" },
                  { id: 140, name: "machine id 140" },
                  { id: 141, name: "machine id 141" },
                  { id: 142, name: "machine id 142" },
                  { id: 143, name: "machine id 143" },
                  { id: 144, name: "machine id 144" },
                  { id: 145, name: "machine id 145" },
                  { id: 146, name: "machine id 146" },
                  { id: 147, name: "machine id 147" },
                  { id: 148, name: "machine id 148" },
                  { id: 149, name: "machine id 149" },
                  { id: 150, name: "machine id 150" },
                  { id: 151, name: "machine id 151" },
                  { id: 152, name: "machine id 152" },
                  { id: 153, name: "machine id 153" },
                  { id: 154, name: "machine id 154" },
                  { id: 155, name: "machine id 155" },
                  { id: 156, name: "machine id 156" },
                  { id: 157, name: "machine id 157" },
                  { id: 158, name: "machine id 158" },
                  { id: 159, name: "machine id 159" },
                  { id: 160, name: "machine id 160" },
                  { id: 161, name: "machine id 161" },
                  { id: 162, name: "machine id 162" },
                  { id: 163, name: "machine id 163" },
                  { id: 164, name: "machine id 164" },
                  { id: 165, name: "machine id 165" },
                  { id: 166, name: "machine id 166" },
                  { id: 167, name: "machine id 167" },
                  { id: 168, name: "machine id 168" },
                  { id: 169, name: "machine id 169" },
                  { id: 170, name: "machine id 170" },
                  { id: 171, name: "machine id 171" },
                  { id: 172, name: "machine id 172" },
                  { id: 173, name: "machine id 173" },
                  { id: 174, name: "machine id 174" },
                  { id: 175, name: "machine id 175" },
                  { id: 176, name: "machine id 176" },
                  { id: 177, name: "machine id 177" },
                  { id: 178, name: "machine id 178" },
                  { id: 179, name: "machine id 179" },
                  { id: 180, name: "machine id 180" },
                  { id: 181, name: "machine id 181" },
                  { id: 182, name: "machine id 182" },
                  { id: 183, name: "machine id 183" },
                  { id: 184, name: "machine id 184" },
                  { id: 185, name: "machine id 185" },
                  { id: 186, name: "machine id 186" },
                  { id: 187, name: "machine id 187" },
                  { id: 188, name: "machine id 188" },
                  { id: 189, name: "machine id 189" },
                  { id: 190, name: "machine id 190" },
                  { id: 191, name: "machine id 191" },
                  { id: 192, name: "machine id 192" },
                  { id: 193, name: "machine id 193" },
                  { id: 194, name: "machine id 194" },
                  { id: 195, name: "machine id 195" },
                  { id: 196, name: "machine id 196" },
                  { id: 197, name: "machine id 197" },
                  { id: 198, name: "machine id 198" },
                  { id: 199, name: "machine id 199" },
                  { id: 200, name: "machine id 200" },
                ]}
              />
            </Grid>
          </Grid>
          {group && (
            <Grid container style={{ paddingTop: 40, paddingBottom: 40 }}>
              {cardsIds && cardsIds.length > 0 && (
                <Grid xs={12} item style={{ marginTop: 30 }}>
                  <CommonButton
                    text={`send card ${cardsIds.length}`}
                    onClick={() => onTransferCardCall()}
                    loading={loadingApi}
                  />
                </Grid>
              )}

              {choosenPlayer &&
                balances.find((balance) => balance.token === "DEC")?.balance >
                  1000 && (
                  <Grid xs={12} item style={{ marginTop: 30 }}>
                    <CommonButton
                      text={`send decs ${
                        balances.find((balance) => balance.token === "DEC")
                          ?.balance - 1000
                      }`}
                      onClick={() => onTransferCredit()}
                      loading={loadingApi}
                    />
                  </Grid>
                )}

              {choosenPlayer &&
                balances &&
                balances.length > 0 &&
                balances.find((balance) => balance.token === "SPS")
                  ?.balance && (
                  <Grid xs={12} item style={{ marginTop: 30 }}>
                    <CommonButton
                      text={`send sps ${
                        balances.find((balance) => balance.token === "SPS")
                          ?.balance
                      }`}
                      onClick={() => onTransferCreditSps()}
                      loading={loadingApi}
                    />
                  </Grid>
                )}

              {choosenPlayer &&
                balances &&
                balances.length > 0 &&
                balances.find((balance) => balance.token === "CHAOS")?.balance >
                  0 && (
                  <Grid xs={12} item style={{ marginTop: 30 }}>
                    <Typography>You Have a CAOS Pack</Typography>
                  </Grid>
                )}
              {players?.map((player) => {
                return (
                  <Grid xs={2} item style={{ marginTop: 10, marginLeft: 10 }}>
                    <Card
                      style={{
                        padding: 10,
                        cursor: "pointer",
                        border:
                          player?.name === choosenPlayer?.name
                            ? "2px solid green"
                            : "1px solid grey",
                      }}
                      elevation={5}
                      onClick={() => setChoosenPlayer(player)}
                    >
                      <Grid>
                        <strong>{player.name}</strong>
                      </Grid>
                      <Grid style={{ marginTop: 10 }}>
                        <Typography
                          style={{
                            color:
                              parseInt(
                                player?.game_type === "Wild"
                                  ? player?.splinterlandInfo?.rating
                                  : player?.splinterlandInfo?.modern_rating
                              ) < 499
                                ? "red"
                                : "black",
                          }}
                        >
                          RATING:{" "}
                          {player?.game_type === "Wild"
                            ? player?.splinterlandInfo?.rating
                            : player?.splinterlandInfo?.modern_rating}
                        </Typography>
                      </Grid>
                      <Grid style={{ marginTop: 10 }}>
                        <Typography
                          style={{
                            color:
                              renderDec(player) < 600
                                ? "red"
                                : renderDec(player) > 500 &&
                                  renderDec(player) < 500
                                ? "orange"
                                : "black",
                          }}
                        >
                          DEC: {renderDec(player)}
                        </Typography>
                      </Grid>
                      <Grid style={{ marginTop: 10 }}>
                        <Typography
                          style={{
                            color: "orange",
                          }}
                        >
                          CREDITS: {renderCurrency(player)}
                        </Typography>
                      </Grid>
                      <Grid style={{ marginTop: 10 }}>
                        <Typography
                          style={{
                            color: "orange",
                          }}
                        >
                          MERITS: {renderMerits(player)}
                        </Typography>
                      </Grid>
                      <Grid style={{ marginTop: 5 }}>
                        COLLECTION:
                        <strong>
                          {player.splinterlandInfo?.collection_power}
                        </strong>
                      </Grid>
                      <Grid style={{ marginTop: 5 }}>
                        FINISH RENT:
                        <strong>{player.has_finish_rent}</strong>
                      </Grid>
                      <Grid style={{ marginTop: 5 }}>
                        IS PLAYING:
                        <strong>{player.is_playing}</strong>
                      </Grid>
                      <Grid style={{ marginTop: 10 }}>
                        <Typography>
                          {player?.combination_ids || player?.combination}
                        </Typography>
                      </Grid>
                      {player.balances.find(
                        (balance) => balance.token === "ECR"
                      ) && (
                        <Grid style={{ marginTop: 5 }}>
                          ECR:
                          <strong>{`${calculateECR(
                            player.balances.find(
                              (balance) => balance.token === "ECR"
                            )?.balance,
                            settings.energy.hourly_regen_rate,
                            player.balances.find(
                              (balance) => balance.token === "ECR"
                            )?.last_reward_time,
                            settings.energy.max_energy
                          ).toFixed(2)} `}</strong>
                        </Grid>
                      )}
                      <Grid style={{ marginTop: 5 }}>{player.game_type}</Grid>
                    </Card>
                  </Grid>
                );
              })}
              {cardsIds && cardsIds.length > 0 && (
                <Grid xs={12} item style={{ marginTop: 30 }}>
                  <CommonButton
                    text={`send card ${cardsIds.length}`}
                    onClick={() => onTransferCardCall()}
                    loading={loadingApi}
                  />
                </Grid>
              )}

              {choosenPlayer &&
                balances.find((balance) => balance.token === "DEC")?.balance >
                  1000 && (
                  <Grid xs={12} item style={{ marginTop: 30 }}>
                    <CommonButton
                      text={`send decs ${
                        balances.find((balance) => balance.token === "DEC")
                          ?.balance - 1000
                      }`}
                      onClick={() => onTransferCredit()}
                      loading={loadingApi}
                    />
                  </Grid>
                )}

              {choosenPlayer &&
                balances &&
                balances.length > 0 &&
                balances.find((balance) => balance.token === "SPS")
                  ?.balance && (
                  <Grid xs={12} item style={{ marginTop: 30 }}>
                    <CommonButton
                      text={`send sps ${
                        balances.find((balance) => balance.token === "SPS")
                          ?.balance
                      }`}
                      onClick={() => onTransferCreditSps()}
                      loading={loadingApi}
                    />
                  </Grid>
                )}

              {choosenPlayer &&
                balances &&
                balances.length > 0 &&
                balances.find((balance) => balance.token === "CHAOS")?.balance >
                  0 && (
                  <Grid xs={12} item style={{ marginTop: 30 }}>
                    <Typography>You Have a CAOS Pack</Typography>
                  </Grid>
                )}
            </Grid>
          )}
        </Container>
      </SideMenu>
    </>
  );
};

export default MoveCardsPage;
