import React, { useState, useEffect } from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import CommonButton from "../FormComponents/CommonButton";
import {
  createGame,
  getNextGameType,
  increaseBattleTypeId,
  copyEntireCombination,
  getBestTeamPossible,
  getBestTeamPossibleV2,
} from "../../Axios/Call/AuthApi";
import AlertGeneral from "../Commons/AlertGeneral/AlertGeneral";
import { AiFillDelete } from "react-icons/ai";
import { FormAddCard } from "./FormAddCard";

const SimulationSection = ({ summonerCombination, cardsDetail, settings }) => {
  const [startSimulation, setStartSimulation] = useState(false);
  const [gameType, setGameType] = useState({});
  const [cardsChoosen, setCardChoosen] = useState([]);
  const [myGamePlayed, setMyGamePlayed] = useState(false);
  const [hasToFatchGame, setHasToFatchGame] = useState(true);
  const [cardsAvailableThisGame, setCardAvailableThisGame] = useState(
    summonerCombination.summoner_cards
  );
  const [loadingBattle, setLoadingBattle] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const onClickStartBattle = () => {
    setLoadingBattle(true);
    getNextGameType(summonerCombination.last_game_id_played + startSimulation)
      .then((response) => {
        setLoadingBattle(false);
        setStartSimulation(1);
        setGameType(response.data);
      })
      .catch((error) => {
        setLoadingBattle(false);
        setErrorApi(true);
      });
  };

  const onClickNextBattle = () => {
    if (!cardsChoosen.length) {
      return;
    }

    setLoadingBattle(true);
    setCardAvailableThisGame(summonerCombination.summoner_cards);
    createGame({
      rule_one_id: gameType.rule_one_id,
      summoner_id: summonerCombination.card_id,
      mana_cap: gameType.mana_cap,
      cards: JSON.stringify(cardsChoosen.map((card) => card.id)),
      game_type: summonerCombination.game_type,
    })
      .then((response) => {})
      .catch((error) => {
        setErrorApi(true);
      });

    increaseBattleTypeId(summonerCombination.id)
      .then((response) => {
        getNextGameType(
          summonerCombination.last_game_id_played + startSimulation
        )
          .then((response) => {
            setLoadingBattle(false);
            setStartSimulation(startSimulation + 1);
            setGameType(response.data);
          })
          .catch((error) => {
            setLoadingBattle(false);
            setErrorApi(true);
          });
      })
      .catch((error) => {
        setLoadingBattle(false);
        setErrorApi(true);
      });
  };

  const skipToNextGame = () => {
    increaseBattleTypeId(summonerCombination.id)
      .then((response) => {
        getNextGameType(
          summonerCombination.last_game_id_played + startSimulation
        )
          .then((response) => {
            setLoadingBattle(false);
            setStartSimulation(startSimulation + 1);
            setGameType(response.data);
          })
          .catch((error) => {
            setLoadingBattle(false);
            setErrorApi(true);
          });
      })
      .catch((error) => {
        setLoadingBattle(false);
        setErrorApi(true);
      });
  };

  useEffect(() => {
    if (Object.keys(gameType).length) {
      if (gameType.rule_one?.name === "Rise of the Commons") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter(
            (card) =>
              card.card.rarity === "common" || card.card.rarity === "rare"
          )
        );
      }
      if (gameType.rule_one?.name === "Even Stevens") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.mana % 2 === 0)
        );
      }
      if (gameType.rule_one?.name === "Broken Arrows") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.type !== "ranged")
        );
      }
      if (gameType.rule_one?.name === "Little League") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.mana <= 4)
        );
      }
      if (gameType.rule_one?.name === "Keep Your Distance") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.type !== "melee")
        );
      }
      if (gameType.rule_one?.name === "Lost Magic") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.type !== "mage")
        );
      }
      if (gameType.rule_one?.name === "Odd Ones Out") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.mana % 2 !== 0)
        );
      }
      if (gameType.rule_one?.name === "Taking Sides") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.color !== "neutral")
        );
      }
      if (gameType.rule_one?.name === "Lost Legendaries") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter(
            (card) => card.card.rarity !== "leggendary"
          )
        );
      }
      if (gameType.rule_one?.name === "Up Close & Personal") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.type === "melee")
        );
      }
      if (gameType.rule_one?.name === "Wands Out") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.type === "mage")
        );
      }
      if (gameType.rule_one?.name === "Going the Distance") {
        setCardAvailableThisGame(
          cardsAvailableThisGame.filter((card) => card.card.type === "ranged")
        );
      }
    }
  }, [gameType]);

  //
  useEffect(() => {
    if (Object.keys(gameType).length && hasToFatchGame) {
      getBestTeamPossibleV2({
        mana: gameType.mana_cap,
        ruleset: gameType.rule_one?.name,
        mappedSplinter: [
          {
            summoner: summonerCombination.card_id,
            type: summonerCombination.game_type,
          },
        ],
        combinationChoosen: ` ${summonerCombination.env_name}`,
        questToPrioritize: "none",
      })
        .then((response) => {
          console.log(response.data);
          let cards = [];
          setMyGamePlayed(response.data);
          response?.data?.cards?.forEach((card) => {
            const foundCard = cardsAvailableThisGame?.find(
              (cardObject) =>
                parseInt(cardObject?.card?.uid) === parseInt(card?.card_id)
            )?.card;
            if (foundCard) {
              cards.push(foundCard);
            }
          });

          setCardChoosen(cards);
        })
        .catch((error) => {
          console.log(error);
          // setCardChoosen([]);
        });
    }
  }, [gameType]);

  const onAddCardHandler = (card) => {
    setCardChoosen(cardsChoosen.concat(card));
  };

  const choosenCardsMana = () => {
    let mana = summonerCombination.mana;
    cardsChoosen.forEach((card) => {
      mana += card.mana;
    });

    return mana;
  };

  const cannotAddCard = (card) => {
    if (
      cardsChoosen.find((choseCard) => choseCard.id === card.id) ||
      choosenCardsMana() > gameType.mana_cap ||
      cardsChoosen.length > 5 ||
      choosenCardsMana() + card.mana > gameType.mana_cap
    ) {
      return true;
    }

    return false;
  };

  const changeOrder = (oldIndex, newIndex) => {
    if (!isNaN(newIndex)) {
      const foundItem = cardsChoosen.find((card, index) => index === oldIndex);
      let newCards = cardsChoosen.filter((card, index) => index !== oldIndex);
      newCards.splice(newIndex, 0, foundItem);

      setCardChoosen(newCards);
    }
  };

  const getCardLevelInfo = ({ edition, cardDetail, isGold, xp }) => {
    let cardXp = xp;
    let level = 0;
    if (isNaN(xp)) {
      cardXp = (edition === 4 || cardDetail?.tier) === 4 ? 1 : 0;
    }

    if (edition === 4 || cardDetail?.tier >= 4) {
      const rates = isGold
        ? settings.combine_rates_gold[cardDetail?.rarity - 1]
        : settings.combine_rates[cardDetail?.rarity - 1];

      for (let i = 0; i < rates.length; i += 1) {
        if (rates[i] > cardXp) {
          break;
        }
        level += 1;
      }

      if (!cardXp) {
        level = 1;
      }

      return {
        level,
        xp_to_next_level: cardXp - rates[level - 1],
        cards_to_next_level: cardXp - rates[level - 1],
        xp_needed: level >= rates.length ? -1 : rates[level] - rates[level - 1],
        cards_needed:
          level >= rates.length ? -1 : rates[level] - rates[level - 1],
      };
    }
  };

  const onFindCardDetail = (cardId) => {
    return cardsDetail?.find((cardDetail) => cardDetail.id === cardId);
  };

  const getCardsStats = (cardId, card) => {
    const rarityGold = {
      common: 60,
      epic: 10,
      rare: 25,
      leggendary: 3,
    };

    const rarity = {
      common: 14,
      epic: 4,
      rare: 5,
      leggendary: 1,
    };

    const BetaLevel = {
      common: 60,
      epic: 350,
      rare: 150,
      leggendary: 1,
    };
    // TODO calculate card level
    const level =
      Math.min(
        100,
        getCardLevelInfo({
          edition: parseInt(card?.card?.edition),
          cardDetail: onFindCardDetail(cardId),
          isGold: false,
          xp:
            parseInt(card?.card?.edition) === 1
              ? BetaLevel[card?.card?.rarity]
              : [56, 57, 58, 59, 60, 61, 62, 63].includes(
                  summonerCombination.id
                )
              ? rarityGold[card?.card?.rarity]
              : rarity[card?.card?.rarity],
        })?.level
      ) || 1;
    const stats = onFindCardDetail(cardId)?.stats;
    if (!stats) {
      return {
        mana: 0,
        attack: 0,
        magic: 0,
        armor: 0,
        health: 0,
        speed: 0,
        abilities: [],
        level: 1,
      };
    }

    if (onFindCardDetail(cardId).type === "Summoner") {
      return {
        stats: {
          ...stats,
          mana: undefined,
        },
        level,
        mana: stats.mana,
      };
    }

    const abilities = [];
    for (let i = 0; i < level; i += 1) {
      stats?.abilities[i]
        .filter((a) => a !== "")
        .forEach((a) => abilities.push(a));
    }

    return {
      mana: stats.mana[level - 1],
      attack: stats.attack[level - 1],
      ranged: stats.ranged ? stats.ranged[level - 1] : 0,
      magic: stats.magic[level - 1],
      armor: stats.armor[level - 1],
      health: stats.health[level - 1],
      speed: stats.speed[level - 1],
      abilities,
      level,
    };
  };

  const onCopyEntireCombination = () => {
    copyEntireCombination(gameType.id, summonerCombination.id)
      .then((response) => {
        getNextGameType(response.data.last_game_id_played)
          .then((response) => {
            setLoadingBattle(false);
            setStartSimulation(response.data.last_game_id_played + 1);
            setGameType(response.data);
          })
          .catch((error) => {
            setLoadingBattle(false);
            setErrorApi(true);
          });
      })
      .catch((error) => {});
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Errore Caricamento"}
        />
      )}
      <Grid container>
        <Grid xs={12} style={{ marginTop: 20 }}>
          <Typography>Simulation battle</Typography>
        </Grid>
        {myGamePlayed && (
          <Grid xs={12} style={{ marginTop: 40 }}>
            {myGamePlayed?.game?.enemy_match_simulated} -{" "}
            {myGamePlayed?.game?.percentage}
          </Grid>
        )}
        <Grid xs={12} style={{ marginTop: 20 }}>
          <CommonButton
            text={hasToFatchGame ? "Is fetching" : "Is not fatching"}
            onClick={() => setHasToFatchGame(!hasToFatchGame)}
            style={{ width: 200 }}
          />
        </Grid>
        {Object.keys(gameType).length && (
          <>
            {gameType?.rule_one_id !== 1 &&
              !gameType?.rule_one?.is_blocking && (
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <CommonButton
                    text={"Copy entire combination"}
                    onClick={() => onCopyEntireCombination()}
                  />
                </Grid>
              )}
            <Grid xs={12} style={{ marginTop: 10 }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    backgroundColor: "lightblue",
                    padding: 10,
                    borderRadius: 100,
                  }}
                >
                  <span style={{ fontSize: 30, fontWeight: "bolder" }}>
                    {gameType.mana_cap} / {choosenCardsMana()}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingLeft: 20,
                  }}
                >
                  <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                    {gameType.rule_one?.name}
                  </span>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <button onClick={() => skipToNextGame()}>SKIP</button>
                </div>
              </div>
            </Grid>
            <Grid xs={12} style={{ marginTop: 20 }}>
              <Grid container>
                {cardsChoosen.map((card, index) => {
                  return (
                    <Grid xs={2} item>
                      <Grid xs={12} item>
                        <input
                          value={index}
                          onChange={(event) =>
                            changeOrder(index, parseInt(event.target.value))
                          }
                          type={"number"}
                          style={{ width: "50%" }}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <Typography>
                          <strong>{`${card.mana}`}</strong>
                          {` ${card.name}`}
                        </Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <div style={{ position: "relative" }}>
                          <img
                            src={card.picture}
                            alt={card.name}
                            onClick={() =>
                              setCardChoosen(cardsChoosen.concat(card))
                            }
                            style={{
                              cursor: "pointer",
                              width: "40%",
                            }}
                          />
                          <AiFillDelete
                            color="red"
                            style={{
                              position: "absolute",
                              top: -10,
                              cursor: "pointer",
                            }}
                            size={25}
                            onClick={() =>
                              setCardChoosen(
                                cardsChoosen.filter(
                                  (filterCard) => filterCard.id !== card.id
                                )
                              )
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
        <Grid style={{ marginTop: 20, marginBottom: 100 }} xs={12}>
          <CommonButton
            text={startSimulation ? "Play" : "Start"}
            onClick={() => {
              if (startSimulation) {
                onClickNextBattle();
              } else {
                onClickStartBattle();
              }
            }}
            loading={loadingBattle}
            style={{ width: 200 }}
          />
        </Grid>
        {myGamePlayed?.cards?.length && (
          <Grid xs={12} style={{ marginTop: 40 }} container>
            {myGamePlayed?.cards?.map((card) => {
              console.log(card);
              if (
                cardsAvailableThisGame?.find(
                  (cardObject) =>
                    parseInt(cardObject?.card?.uid) === parseInt(card?.card_id)
                )
              ) {
                return (
                  <Grid xs={12} md={2}>
                    <img
                      src={
                        cardsAvailableThisGame?.find(
                          (cardObject) =>
                            parseInt(cardObject?.card?.uid) ===
                            parseInt(card?.card_id)
                        ).card.picture
                      }
                      alt={
                        cardsAvailableThisGame?.find(
                          (cardObject) =>
                            parseInt(cardObject?.card?.uid) ===
                            parseInt(card?.card_id)
                        ).card.name
                      }
                      style={{
                        cursor: "pointer",
                        width: "50%",
                      }}
                    />
                  </Grid>
                );
              }

              return null;
            })}
          </Grid>
        )}
        <Grid xs={12} md={3} style={{ marginTop: 40 }}>
          <Grid container>
            <Grid xs={12}>
              <Typography>
                <strong>{`${summonerCombination.name}`}</strong>
              </Typography>
            </Grid>
            <Grid xs={12}>
              <img
                src={summonerCombination.picture_path}
                alt={summonerCombination.name}
                style={{
                  width: "50%",
                  border: "5px solid red",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {cardsAvailableThisGame?.map((cardObject) => {
          return (
            <>
              <Grid xs={12} md={3} style={{ marginTop: 40 }}>
                <Grid container>
                  <Grid xs={12}>
                    <Typography>
                      <strong>{`${cardObject.card.mana}`}</strong>
                      {` ${cardObject.card.name}`} - {cardObject?.card?.uid}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <div style={{ position: "relative" }}>
                      <img
                        src={cardObject.card.picture}
                        alt={cardObject.card.name}
                        onClick={() => {
                          if (cannotAddCard(cardObject.card)) {
                            return;
                          } else {
                            onAddCardHandler(cardObject.card);
                          }
                        }}
                        style={{
                          opacity: cardsChoosen.find(
                            (choseCard) => choseCard.id === cardObject.card.id
                          )
                            ? "0.4"
                            : "none",
                          cursor: "pointer",
                          width: "70%",
                          border: cardObject.is_strong_combination
                            ? "5px solid green"
                            : "none",
                        }}
                      />
                      <div
                        style={{
                          top: 0,
                          left: 28,
                          position: "absolute",
                          zIndex: 100,
                        }}
                      >
                        <img
                          src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/stat_bg_mana.png`}
                          style={{ height: 50 }}
                          alt="niente"
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 4,
                            width: 46,
                            textAlign: "center",
                            color: "white",
                            fontSize: "2rem",
                            fontWeight: "bolder",
                            "-webkit-text-stroke": "1.5px black",
                            textShadow: "2px 2px black",
                          }}
                        >
                          {
                            getCardsStats(cardObject?.card?.id, cardObject)
                              ?.mana
                          }
                        </div>
                      </div>
                      {getCardsStats(cardObject?.card?.id, cardObject)
                        ?.health ? (
                        <div
                          style={{
                            top: 100,
                            right: 15,
                            position: "absolute",
                            zIndex: 100,
                          }}
                        >
                          <img
                            src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png`}
                            style={{ height: 52 }}
                            alt="healt"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: 8,
                              left: 8,
                              width: 30,
                              textAlign: "center",
                              color: "white",
                              fontSize: "27px",
                              fontWeight: "bolder",
                              "-webkit-text-stroke": "1.5px black",
                              textShadow: "2px 2px black",
                            }}
                          >
                            {
                              getCardsStats(cardObject?.card?.id, cardObject)
                                ?.health
                            }
                          </div>
                        </div>
                      ) : null}
                      {getCardsStats(cardObject?.card?.id, cardObject)
                        ?.magic ? (
                        <div
                          style={{
                            top: 50,
                            left: 20,
                            position: "absolute",
                            zIndex: 100,
                          }}
                        >
                          <img
                            src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/magic.png`}
                            style={{ height: 52 }}
                            alt="healt"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: 8,
                              left: 8,
                              width: 30,
                              textAlign: "center",
                              color: "white",
                              fontSize: "27px",
                              fontWeight: "bolder",
                              "-webkit-text-stroke": "1.5px black",
                              textShadow: "2px 2px black",
                            }}
                          >
                            {
                              getCardsStats(cardObject?.card?.id, cardObject)
                                ?.magic
                            }
                          </div>
                        </div>
                      ) : null}
                      {getCardsStats(cardObject?.card?.id, cardObject)
                        ?.ranged ? (
                        <div
                          style={{
                            top: 50,
                            left: 10,
                            position: "absolute",
                            zIndex: 100,
                          }}
                        >
                          <img
                            src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged.png`}
                            style={{ height: 52 }}
                            alt="healt"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: 8,
                              left: 8,
                              width: 30,
                              textAlign: "center",
                              color: "white",
                              fontSize: "27px",
                              fontWeight: "bolder",
                              "-webkit-text-stroke": "1.5px black",
                              textShadow: "2px 2px black",
                            }}
                          >
                            {
                              getCardsStats(cardObject?.card?.id, cardObject)
                                ?.ranged
                            }
                          </div>
                        </div>
                      ) : null}
                      {getCardsStats(cardObject?.card?.id, cardObject)
                        ?.attack ? (
                        <div
                          style={{
                            top: 50,
                            left: 20,
                            position: "absolute",
                            zIndex: 100,
                          }}
                        >
                          <img
                            src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/attack.png`}
                            style={{ height: 52 }}
                            alt="attack"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: 8,
                              left: 8,
                              width: 30,
                              textAlign: "center",
                              color: "white",
                              fontSize: "27px",
                              fontWeight: "bolder",
                              "-webkit-text-stroke": "1.5px black",
                              textShadow: "2px 2px black",
                            }}
                          >
                            {
                              getCardsStats(cardObject?.card?.id, cardObject)
                                ?.attack
                            }
                          </div>
                        </div>
                      ) : null}
                      {getCardsStats(cardObject?.card?.id, cardObject)
                        ?.armor ? (
                        <div
                          style={{
                            top: 50,
                            right: 20,
                            position: "absolute",
                            zIndex: 100,
                          }}
                        >
                          <img
                            src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/armor.png`}
                            style={{ height: 52 }}
                            alt="healt"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: 8,
                              left: 8,
                              width: 30,
                              textAlign: "center",
                              color: "white",
                              fontSize: "27px",
                              fontWeight: "bolder",
                              "-webkit-text-stroke": "1.5px black",
                              textShadow: "2px 2px black",
                            }}
                          >
                            {
                              getCardsStats(cardObject?.card?.id, cardObject)
                                ?.armor
                            }
                          </div>
                        </div>
                      ) : null}
                      {getCardsStats(cardObject?.card?.id, cardObject)
                        ?.speed ? (
                        <div
                          style={{
                            top: 100,
                            left: 20,
                            position: "absolute",
                            zIndex: 100,
                          }}
                        >
                          <img
                            src={`https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png`}
                            style={{ height: 52 }}
                            alt="healt"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: 8,
                              left: 8,
                              width: 30,
                              textAlign: "center",
                              color: "white",
                              fontSize: "27px",
                              fontWeight: "bolder",
                              "-webkit-text-stroke": "1.5px black",
                              textShadow: "2px 2px black",
                            }}
                          >
                            {
                              getCardsStats(cardObject?.card?.id, cardObject)
                                ?.speed
                            }
                          </div>
                        </div>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          gap: 5,
                          justifyContent: "center",
                          position: "absolute",
                          top: 200,
                          left: 80,
                        }}
                      >
                        {onFindCardDetail(cardObject?.card?.id)?.type !==
                        "Summoner"
                          ? getCardsStats(
                              cardObject?.card?.id,
                              cardObject
                            )?.abilities?.map((ability) => (
                              <img
                                src={`https://d36mxiodymuqjm.cloudfront.net/website/abilities/ability_${ability
                                  ?.toLowerCase()
                                  .replace(" ", "-")}.png`}
                                key={ability}
                                style={{ width: 35 }}
                                alt="somenthing"
                              />
                            ))
                          : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
        <FormAddCard id={summonerCombination.id} />
      </Grid>
    </>
  );
};

export default SimulationSection;
