import React, { useEffect } from "react";
import { Grid, Typography, Modal, Switch } from "@material-ui/core";
import text from "../../utils/text";
import { Artisan, Cross } from "../../utils/icons";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import { Controller, useForm } from "react-hook-form";
import CommonButton from "../../Components/FormComponents/CommonButton";
import CommonSelect from "../../Components/FormComponents/CommonSelect";

const ModalAddCard = ({
  setOpenModalAdd,
  openModalAdd,
  onCreateSupplier,
  loadingApiAdd,
  editSupplier,
  setEditSupplier,
}) => {
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const createNewArtisan = (data) => {
    onCreateSupplier(data);
  };

  useEffect(() => {
    if (Object.keys(editSupplier).length) {
      reset({ ...editSupplier });
    } else {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSupplier]);

  return (
    <Modal open={openModalAdd} size={"xl"}>
      <Grid container className={"modalWrapperWeb"}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenModalAdd(false);
            setEditSupplier({});
          }}
        >
          <Cross />
        </div>
        <Grid item align="left" style={{ paddingTop: 5 }}>
          <Artisan size={38} fill="#28395A" />
        </Grid>
        <Grid item align="left" style={{ paddingLeft: 10 }}>
          <Typography variant="h2">{"New Card"}</Typography>
        </Grid>
        <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
          <form
            onSubmit={handleSubmit(createNewArtisan)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={"UID"}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"uid"}
                  error={errors.uid}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={"Name"}
                  type="input"
                  ref={register({
                    required: text.General.required,
                  })}
                  defaultValue=""
                  name={"name"}
                  error={errors.name}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={"Picture"}
                  type="input"
                  ref={register({
                    required: text.General.required,
                  })}
                  defaultValue=""
                  name={"picture"}
                  error={errors.picture}
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={"Edition"}
                  ref={register({
                    required: text.General.required,
                  })}
                  defaultValue=""
                  name={"edition"}
                  error={errors.edition}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={"50K gold"}
                  ref={register({})}
                  defaultValue=""
                  name={"is_legendary_50_gold"}
                  error={errors.is_legendary_50_gold}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={"Mana"}
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"mana"}
                  error={errors.mana}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect
                      name="color"
                      options={[
                        { id: "red", name: "red" },
                        { id: "blue", name: "blue" },
                        { id: "black", name: "black" },
                        { id: "green", name: "green" },
                        { id: "white", name: "white" },
                        { id: "neutral", name: "neutral" },
                      ]}
                      field={field}
                      labelCustom={"Color"}
                    />
                  )}
                  control={control}
                  defaultValue={""}
                  rules={{ required: text.General.required }}
                  name="color"
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect
                      name="type"
                      options={[
                        { id: "none", name: "none" },
                        { id: "ranged", name: "ranged" },
                        { id: "melee", name: "melee" },
                        { id: "mage", name: "mage" },
                      ]}
                      field={field}
                      labelCustom={"Type"}
                    />
                  )}
                  control={control}
                  defaultValue={""}
                  rules={{ required: text.General.required }}
                  name="type"
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect
                      name="rarity"
                      options={[
                        { id: "common", name: "common" },
                        { id: "rare", name: "rare" },
                        { id: "epic", name: "epic" },
                        { id: "leggendary", name: "leggendary" },
                      ]}
                      field={field}
                      labelCustom={"Rarity"}
                    />
                  )}
                  control={control}
                  defaultValue={""}
                  rules={{ required: text.General.required }}
                  name="rarity"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={4} align="center">
                    <CommonButton
                      loading={loadingApiAdd}
                      text={editSupplier ? "Modfiica Carta" : "Crea Carta"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalAddCard;
